import React, { useEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Peer from "simple-peer";
import io from "socket.io-client";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AssignmentIcon from "@material-ui/icons/Assignment";

import PhoneIcon from "@material-ui/icons/Phone";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";

import { Video } from "./components/video";

import jinx from "./assets/jinx.gif";
import wei from "./assets/wei.gif";

import "./App.css";

const END_POINT =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
        ? "http://localhost:5050"
        : "https://gtmd-wechat.herokuapp.com/";

const socket = io.connect(END_POINT);

function App() {
    const [me, setMe] = useState("");
    const [stream, setStream] = useState();
    const [receivingCall, setReceivingCall] = useState(false);
    const [caller, setCaller] = useState("");
    const [callerSignal, setCallerSignal] = useState();
    const [callAccepted, setCallAccepted] = useState(false);
    const [idToCall, setIdToCall] = useState("");
    const [callEnded, setCallEnded] = useState(false);
    const [name, setName] = useState("");
    const [muted, setMuted] = useState(false);

    const myVideo = useRef();
    const userVideo = useRef();
    const connectionRef = useRef();

    useEffect(() => {
        navigator.mediaDevices
            .getUserMedia({ video: false, audio: true })
            .then((stream) => {
                setStream(stream);
                myVideo.current.srcObject = stream;
            })
            .catch(() => {});

        socket.on("me", (id) => {
            setMe(id);
        });

        socket.on("callUser", (data) => {
            setReceivingCall(true);
            setCaller(data.from);
            setName(data.name);
            setCallerSignal(data.signal);
        });
    }, []);

    const callUser = (id) => {
        const peer = new Peer({
            initiator: true,
            trickle: false,
            stream: stream,
        });

        peer.on("signal", (data) => {
            socket.emit("callUser", {
                userToCall: id,
                signalData: data,
                from: me,
                name: name,
            });
        });

        peer.on("stream", (stream) => {
            userVideo.current.srcObject = stream;
        });

        socket.on("callAccepted", (signal) => {
            setCallAccepted(true);
            peer.signal(signal);
        });

        connectionRef.current = peer;
    };

    const answerCall = () => {
        setCallAccepted(true);

        const peer = new Peer({
            initiator: false,
            trickle: false,
            stream: stream,
        });

        peer.on("signal", (data) => {
            socket.emit("answerCall", { signal: data, to: caller });
        });

        peer.on("stream", (stream) => {
            userVideo.current.srcObject = stream;
        });

        peer.signal(callerSignal);

        connectionRef.current = peer;
    };

    const leaveCall = () => {
        setCallEnded(true);

        connectionRef.current.destroy();

        window.location.reload();

        // socket.on("callEnded", (data) => {
        //     console.log(data);
        // });
    };

    const turnOffAudio = () => {
        const audioTrack = stream.getAudioTracks()[0];

        if (audioTrack.enabled) {
            audioTrack.enabled = false;
            setMuted(true);
        } else {
            audioTrack.enabled = true;
            setMuted(false);
        }
    };

    return (
        <>
            <h1 style={{ textAlign: "center" }}>GTMD WeChat</h1>
            <div className="container">
                <div className="videoContainer">
                    <div className="video">
                        {stream && <Video videoRef={myVideo} poster={jinx} />}
                        Hunag
                    </div>
                    <div className="video">
                        <>
                            <Video videoRef={userVideo} poster={wei} />
                            胸湿昆
                        </>
                    </div>
                </div>

                <div className="buttons">
                    <IconButton
                        color="secondary"
                        aria-label="camera"
                        onClick={turnOffAudio}
                    >
                        {muted ? (
                            <MicOffIcon fontSize="large" />
                        ) : (
                            <MicIcon fontSize="large" />
                        )}
                    </IconButton>
                </div>

                <div className="info">
                    <CopyToClipboard text={me} style={{ marginBottom: "2rem" }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<AssignmentIcon fontSize="large" />}
                        >
                            Copy ID
                        </Button>
                    </CopyToClipboard>
                </div>

                <div>
                    <TextField
                        id="filled-basic"
                        label="ID to Call"
                        variant="standard"
                        value={idToCall}
                        onChange={(e) => setIdToCall(e.target.value)}
                    />
                    <div className="call-button">
                        {callAccepted && !callEnded ? (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={leaveCall}
                            >
                                End Call
                            </Button>
                        ) : (
                            <IconButton
                                color="secondary"
                                aria-label="call"
                                onClick={() => callUser(idToCall)}
                            >
                                <PhoneIcon fontSize="large" />
                            </IconButton>
                        )}
                        {!callAccepted && !callEnded
                            ? "(waiting for join)"
                            : ""}
                        {idToCall}
                    </div>
                </div>

                <div>
                    {receivingCall && !callAccepted ? (
                        <div className="caller">
                            <h1>你妈喊你回家打大乱斗了！</h1>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={answerCall}
                            >
                                Answer
                            </Button>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
}

export default App;
