import React from "react";

import "./video.css";

const Video = ({ videoRef, poster }) => {
    return (
        <>
            <video
                className="video"
                playsInline
                autoPlay
                ref={videoRef}
                poster={poster}
            />
        </>
    );
};

export { Video };
